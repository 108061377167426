import { toast } from 'react-toastify';

const options = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const showSuccessToast = (message) => {
    toast.success(message, options);
}

export const showErrorToast = (message) => {
    toast.error(message, options);
}