import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Home from "../components/home";
import AboutMe from "../components/about-me";
import Projects from "../components/projects";
import Portfolio from "../components/portfolio"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Ishan Baniya" />
    <Home />
    <AboutMe />
    <Projects />
    <Portfolio />
    <Contact />
  </Layout>
)

export default IndexPage
