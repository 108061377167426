import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import classes from "./aboutme.module.css";

const AboutMe = () => {
    const data = useStaticQuery(graphql`
        query {
            headshotImage: file(relativePath: { eq: "images/me.png" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <section id="about" className="mt-5 pt-5">
            <Container>
                <Row>
                    <Col md={6}>
                        <Img fluid={data.headshotImage.childImageSharp.fluid} alt="Photo of Ishan Baniya" />
                    </Col>

                    <Col md={6} className="p-5 flex align-items-center">
                        <h1 className={classes.header}>About Me</h1>
                        <hr className={classes.aboutLine} />
                        <p className="p-2">
                            I'm Ishan. I am from Nepal and currently pursing Bachelors degree in Computer Science at
							Georgia Institute of Technology. <br />
                            <span className="lead"> GO JACKETS!</span>
                        </p>

                        <p className="p-2">
                            I enjoy solving problems with technology. When I'm not coding, you can find me hiking or
                            engaged in photography.
                    </p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default AboutMe;