import React from "react";
import PropTypes from "prop-types";
import { VscSearch } from "react-icons/vsc";
import Img from "gatsby-image"
import classes from "./photoCell.module.css";

const PhotoCell = ({ imgFluid, link }) => (
    <div className={classes.photo}>
        <Img fluid={imgFluid} alt="" className={classes.photoImg} />
        <div className={classes.photoOverlay}>
            <a href={link} target="_blank" rel="noreferrer">
                <VscSearch className={classes.search}/>
            </a>
        </div>
    </div>
);

PhotoCell.propType = {
    imgFluid: PropTypes.object.isRequired,
    // image_url: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
}

export default PhotoCell;
