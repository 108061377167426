export const status = {
    SUBMITTING: "submitting",
    SUCCESS: "success",
    ERROR: "error"
}

const isValidEmail = (email) => {
    return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export const validateUserInputs = (name, email, message, subject) => {
    const error = {};

    if (!name || name.trim().length === 0) {
        error.name = 'I would love to know who you are';
    }

    if (!email || email.trim().length === 0) {
        let errorMessage = 'I would love to get in touch with you. I promise your email will not be shared with anyone else.'
        error.email = errorMessage;
    } else if (!isValidEmail(email)) {
        error.email = "Umm, I don't think that is an email";
    }

    if (!message || message.trim().length === 0) {
        error.message = 'I would love to know what you have to say';
    }

    if (!subject || subject.trim().length === 0) {
        error.subject = 'I would be grateful if you could summarize your message';
    }

    return error;
}

export const sendMessage = async (e) => {
    const form = e.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();

    return new Promise((resolve, reject) => {
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                resolve("SUCCESS");
            } else {
                reject(JSON.parse(xhr.response));
            }
        };
        xhr.send(data);
    });
}