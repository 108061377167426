import React from "react";
import ReactWordcloud from "react-wordcloud";
import languageData from "./data.json";

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const MAX_COUNTRIES_TO_DISPLAY = 10;

const HelloWordcloud = () => {
    let words = []

    Object.keys(languageData).forEach(function(key) {
        const totalCountries = languageData[key].length;
        let tooltips = "Used in ";
        if (totalCountries <= MAX_COUNTRIES_TO_DISPLAY) {
            tooltips += languageData[key].join(", ")
        } else {
            const countries_to_show = languageData[key].slice(0, MAX_COUNTRIES_TO_DISPLAY + 1).join(", ");
            const remaining_countries = totalCountries - MAX_COUNTRIES_TO_DISPLAY;
            tooltips += `${countries_to_show} and ${remaining_countries} other countries`;
        }

        words.push({
            text: key,
            value: totalCountries,
            tooltips
        })
    });

    const options = {
        enableTooltip: true,
        fontFamily: "Century Gothic,CenturyGothic,AppleGothic,Oxygen",
        fontSizes: [16, 64],
        rotationAngles: [-90, 90],
        fontWeight: "bold",
        rotations: 3,
        deterministic: true,
        colors: ["#4458dc", "#854fee", "#960200", "#52489C", "#52489C", "#52489C", "#F45B69"]
    }

    const callbacks = {
        getWordTooltip: word => word.tooltips
    }

    return (
        <ReactWordcloud 
            words={words} 
            options={options}
            callbacks={callbacks}
        />
    )
};

export default HelloWordcloud;