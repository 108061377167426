import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import socials from "../../utils/socials";
import HelloWordcloud from "../hello-cloud";
import classes from "./home.module.css";

const Home = () => {
    return (
        <section id="home" className="mt-5 pt-5">
            <Container>
                <Row>
                    <Col md={6} className="pt-5">
                        <div className="mb-4">
                            <h1 className="h1 mb-0">NAMASTE</h1>
                            <small className="text-muted">01101000 01100101 01101100 01101100 01101111</small>
                        </div>
                        <hr className={classes.customLine} />

                        <h1 className="display-4"> ISHAN BANIYA <span className="h3">here</span></h1>
                        <small>Full Stack Developer | Photographer | Machine Learning Enthusiast</small>

                        <Container className="d-flex my-3">
                            {
                                socials.map((social, index) => {
                                    return (
                                        <a href={social.link} key={index} className={`${classes.socialLinkContainer} mr-5`} target="_blank" rel="noreferrer">
                                            <div className={classes.socialLink}>
                                                {social.icon}
                                            </div>
                                        </a>
                                    )
                                })
                            }
                        </Container>
                    </Col>
                    <Col md={6}>
                        <HelloWordcloud />
                    </Col>
                </Row>

            </Container>
        </section>
    )
};

export default Home;