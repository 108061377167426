import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { VscGithubAlt } from "react-icons/vsc";
import ProjectInfo from "./project-info";
import Button from "../button";
import { graphql, useStaticQuery } from "gatsby";

export const projectFluidImage = graphql`
    fragment projectFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`;

export const projectPhotoQuery = graphql`
    query {
        tiptracker: file(relativePath: { eq: "images/demos/tiptracker.png" }) {
            ...projectFluidImage
        }
        circlek: file(relativePath: { eq: "images/demos/circlek.png" }) {
            ...projectFluidImage
        }
        namaste: file(relativePath: { eq: "images/demos/namaste.png" }) {
            ...projectFluidImage
        }
        gtJobNotifier: file(relativePath: { eq: "images/demos/gt-job-notifier.png" }) {
            ...projectFluidImage
        }
    }
`;

const Projects = () => {

    const imageData = useStaticQuery(projectPhotoQuery);

    const data = [
        {
            title: "TipTracker",
            description: `TipTracker is a wage and tip-tracking app that not only allows users to store shifts and calculate their total profit but also utilizes machine learning-backed analytics to empower users to strategically select the most profitable shifts to maximize their earnings.`,
            link: "https://tiptracker.vercel.app",
            fluidImage: imageData["tiptracker"].childImageSharp.fluid,
        },
        {
            title: "GT On-Campus Jobs Notification Service",
            description: `On-Campus jobs often have flexible hours with great pay and can help provide relevant experience to add to your resume. However, there is no way to know when these jobs are posted, and by the time you find them, it's often too late. After signing up for this free service, you will be notified immediately as soon as a new job is posted and help you land your next on-campus job.`,
            link: "https://gtoncampusjobs.com/",
            fluidImage: imageData["gtJobNotifier"].childImageSharp.fluid,
        },
        {
            title: "GT Circle K",
            description: `This is Georgia Tech Circle K International's official website. It allows students to find all the volunteering opportunities near them. It offers dedicated admin dashboard for Club officers to keep track of events and projects with integration to Google Calendar and GroupMe.`,
            link: "https://gtcirclek.appspot.com/",
            fluidImage: imageData["circlek"].childImageSharp.fluid,
        },
        {
            title: "Namaste Savannah",
            description: `Easily configurable restaurant website built with Node.js, React, and MongoDB.`,
            link: "https://namaste-savannah.com/",
            fluidImage: imageData["namaste"].childImageSharp.fluid,
        },
    ];

    return (
        <section id="projects" className="mt-5 pt-5">
            <Container className="pt-5">
                <h1 className="text-center">&lt; Projects /&gt;</h1>
                <p className=" text-center p-2">
                    Explore some of my personal projects.
            </p>

                <Row className="mx-auto">
                    {data.map(({ title, description, link, fluidImage }, index) => {
                        return (
                            <Col md={6} className="mx-auto" key={index}>
                                <ProjectInfo
                                    title={title}
                                    link={link}
                                    description={description}
                                    fluidImage={fluidImage}
                                />
                            </Col>
                        )
                    })}
                </Row>

                <div className="text-center">
                    <p>
                        From Work Scheduler to Personalized
					<a href="https://anki.com/en-us/cozmo.html" className="link" target="_blank" rel="noreferrer"> Cozmo </a>
					Personal Assistant, see more on my Github <VscGithubAlt />
                    </p>
                    <p>
                        <Button link="https://github.com/Nepal-6D48A0">Visit Github</Button>
                    </p>

                </div>

            </Container>
        </section>
    )
};

export default Projects;