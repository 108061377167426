import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { VscEllipsis } from "react-icons/vsc";
import Recaptcha from "react-recaptcha";

import { status, validateUserInputs, sendMessage } from "./utils";
import { showErrorToast, showSuccessToast } from "../toast";
import classes from "./contact.module.css";
import Button from "../button";

const Contact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");

    const [error, setError] = useState({});
    const [submitStatus, setSubmitStatus] = useState();

    const submitSuccess = submitStatus === status.SUCCESS;

    const submitForm = async (e) => {
        e.preventDefault();
        if (submitStatus === status.SUBMITTING) return;

        setSubmitStatus(status.SUBMITTING);

        const inputErrors = validateUserInputs(name, email, message, subject);
        setError(inputErrors);

        if (Object.keys(inputErrors).length === 0) {
            try {
                await sendMessage(e);
                showSuccessToast("Successfully sent the message");
                setSubmitStatus(status.SUCCESS);
            } catch (e) {
                const errorMessage = e.error || "Failed to send the message. Please try again later!";
                showErrorToast(errorMessage);
                setSubmitStatus(status.ERROR);
            }
        } else {
            setSubmitStatus("");
        }
    }

    let submitButton = (
        <Button loading={submitStatus === status.SUBMITTING}>
            Send Message
        </Button>
    );

    if (submitStatus === status.SUCCESS) {
        submitButton = (
            <Button disabled={true}>Message Sent</Button>
        );
    }

    return (
        <section id="contact" className="mt-5 pt-5">
            <Container className="pt-5">
                <div className="text-center">
                    <h1>Contact Me</h1>
                    <p className="lead">
                        <VscEllipsis /> &nbsp;
					Collaborate &nbsp; <VscEllipsis /> &nbsp;
                    Get Coffee &nbsp; <VscEllipsis /> &nbsp;
                    Say Hi &nbsp; <VscEllipsis />
                    </p>
                </div>

                <div className="mx-auto">
                    <form
                        className={classes.contactForm}
                        onSubmit={submitForm}
                        action="https://formspree.io/f/xnqogbdb"
                        method="POST"
                    >
                        <Row>
                            <Col lg={6} className="col-lg-6 wow fadeInLeft">
                                <Form.Group>
                                    <Form.Control type="text" id="name" name="name"
                                        placeholder="Your Name" disabled={submitSuccess}
                                        value={name} onChange={(e) => setName(e.target.value)} />
                                    {error.name && <Form.Text className={classes.error} htmlFor="name">{error.name}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col lg={6} className="col-lg-6 wow fadeInLeft">
                                <Form.Group>
                                    <Form.Control type="text" id="email" aria-describedby="emailHelp"
                                        placeholder="Your Email" name="email" disabled={submitSuccess}
                                        value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {error.email && <Form.Text className={classes.error} htmlFor="email">{error.email}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col lg={12} className="col-lg-12 wow fadeInLeft">
                                <Form.Group>
                                    <Form.Control type="text" disabled={submitSuccess}
                                        id="subject" name="subject" placeholder="Subject"
                                        value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    {error.subject && <Form.Text className={classes.error} htmlFor="subject">{error.subject}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col lg={12} className="col-lg-12 wow fadeInLeft">
                                <Form.Group>
                                    <Form.Control as="textarea" name="message" id="message"
                                        placeholder="Enter your Message" disabled={submitSuccess}
                                        value={message} onChange={(e) => setMessage(e.target.value)} />
                                    {error.message && <Form.Text className={classes.error} htmlFor="message">{error.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Recaptcha sitekey="6LfOowkaAAAAAJNLS20aKjbVKGlWXWyNJyYR98Gi" className="mb-3" />

                        {submitButton}
                    </form>
                </div>
            </Container>
        </section>
    )
};

export default Contact;