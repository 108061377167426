import React from "react";
import PropTypes from "prop-types";
import Spinner from 'react-bootstrap/Spinner'
import classes from "./button.module.css";

const Button = ({ link, loading, disabled, onClick, children }) => {
    if (link) {
        return (
            <a href={link} className={classes.button} target="_blank" rel="noreferrer">
                {children}
            </a>
        )
    } else if (loading) {
        return (
            <button onClick={onClick} className={classes.button}>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </button>
        )
    } else if (disabled) {
        return (
            <button className={`${classes.button} ${classes.disabled}`}>
                {children}
            </button>
        )
    } else {
        return (
            <button onClick={onClick} className={classes.button}>
                {children}
            </button>
        )
    }
};

Button.propType = {
    link: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default Button;