import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classes from "./project.module.css";

const ProjectInfo = ({ title, description, fluidImage, link }) => (
    <div className={classes.project}>
        <Img fluid={fluidImage} alt={`Demo image of ${title} project`} className="img-fluid" />
        <h3 className={classes.projectTitle}>{title}</h3>
        <p>{description}</p>
        <a href={link} className={classes.projectButton} target="_blank" rel="noreferrer">Visit Site</a>
    </div>
);

ProjectInfo.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fluidImage: PropTypes.object,
    link: PropTypes.string,
};

export default ProjectInfo;