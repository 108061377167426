import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '../button'

import PhotoCell from '../photo-cell'

export const gridFluidImage = graphql`
    fragment gridFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

// Cannot use variables in graphql string interpolation
// Hence export the images manually
export const photogridQuery = graphql`
    query {
        sunrise: file(relativePath: { eq: "images/grid-images/sunrise.jpg" }) {
            ...gridFluidImage
        }
        savannah: file(
            relativePath: { eq: "images/grid-images/riverStreet.jpg" }
        ) {
            ...gridFluidImage
        }
        stpatricks: file(
            relativePath: { eq: "images/grid-images/stpatricks.jpg" }
        ) {
            ...gridFluidImage
        }
        bonavature: file(
            relativePath: { eq: "images/grid-images/bonavature.jpg" }
        ) {
            ...gridFluidImage
        }
        scenic: file(
            relativePath: { eq: "images/grid-images/tybeeMorning.jpg" }
        ) {
            ...gridFluidImage
        }
        tybee: file(relativePath: { eq: "images/grid-images/tybee.jpg" }) {
            ...gridFluidImage
        }
    }
`

const Portfolio = () => {
    const links = {
        sunrise: 'https://portfolio.ishanbaniya.com/albums/sunrise-sunset',
        savannah: 'https://portfolio.ishanbaniya.com/albums/savannah',
        stpatricks: 'https://portfolio.ishanbaniya.com/albums/st-patricks',
        bonavature: 'https://portfolio.ishanbaniya.com/albums/bonaventure',
        scenic: 'https://portfolio.ishanbaniya.com/albums/scenic',
        tybee: 'https://portfolio.ishanbaniya.com/albums/tybee',
    }

    const imageData = useStaticQuery(photogridQuery)

    const images = []
    Object.keys(links).forEach(key => {
        images.push({
            link: links[key],
            imageFluid: imageData[key]?.childImageSharp?.fluid,
        })
    })

    return (
        <section id="portfolio" className="mt-5 pt-5">
            <Container className="pt-5">
                <div className="text-center">
                    <h1 className="p-3">Portfolio</h1>
                    <p>Sometimes I just like to grab my camera and get lost.</p>
                </div>

                <Row className="mx-auto">
                    {images.map((img, index) => {
                        return (
                            <Col xs={6} md={4} key={index}>
                                <PhotoCell
                                    link={img.link}
                                    imgFluid={img.imageFluid}
                                />
                            </Col>
                        )
                    })}
                </Row>

                <div className="text-center">
                    <p>See more of my works</p>
                    <Button link="https://portfolio.ishanbaniya.com">
                        Visit Portfolio
                    </Button>
                </div>
            </Container>
        </section>
    )
}

export default Portfolio
